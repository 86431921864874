<template>
  <form @submit.prevent="handleSubmit">
    <FormError v-if="error" :error="error" />
    <fieldset :disabled="isLocked">
      <div class="form-group">
        <label for="p-campaign-id">Campaign</label>
        <!-- <select
          v-model="pledge.campaign_id"
          class="form-control"
          name="campaign_id"
          id="p-campaign-id"
          aria-describedby="campaignHelp"
          @change="setCampaign"
        >
          <option readonly>- Select -</option>
          <option
            v-for="campaign in campaigns"
            :key="campaign.campaign_id"
            :value="campaign.campaign_id"
          >
            {{ campaign.title }}
            <template v-if="isCurrent(campaign.starts_at, campaign.ends_at)">
              (Current)
            </template>
          </option>
        </select> -->
        <Select2
          v-model="pledge.campaign_id"
          :options="campaignOptions"
          :settings="{
            allowClear: true,
            placeholder: '- Select -',
            theme: 'bootstrap4',
            width: '100%',
          }"
          name="campaign_id"
          id="p-campaign-id"
          aria-describedby="campaignHelp"
          @select="setCampaign($event)"
        />
        <small id="campaignHelp" class="form-text text-muted">
          Which campaign will this be a part of?
        </small>
      </div>

      <div :class="{ 'form-group': true, disabled: pledge.campaign_id !== '' }">
        <label for="p-promotion-id">
          Promotion
          <div v-if="isLoadingPromotion" class="spinner-border spinner-border-sm" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </label>
        <!-- <select
          v-model="pledge.promotion_id"
          class="form-control"
          name="promotion_id"
          id="p-promotion-id"
          aria-describedby="promotionHelp"
          :disabled="promotions.length === 0"
          @change="getIntentions"
        >
          <option readonly>- Select -</option>
          <option
            v-for="promotion in promotions"
            :key="promotion.promotion_id"
            :value="promotion.promotion_id"
          >
            {{ promotion.title }}
            <template v-if="isCurrent(promotion.starts_at, promotion.ends_at)">
              (Current)
            </template>
          </option>
        </select> -->
        <Select2
          v-model="pledge.promotion_id"
          :options="promotionOptions"
          :settings="{
            allowClear: true,
            placeholder: '- Select -',
            theme: 'bootstrap4',
            width: '100%',
          }"
          name="promotion_id"
          id="p-promotion-id"
          aria-describedby="promotionHelp"
          :disabled="promotions.length === 0"
          @select="getIntentions($event)"
        />
        <small v-if="pledge.campaign_id !== '' && promotions.length === 0" class="text-muted">
          There are no available promotions for the selected campaign.
        </small>
        <small v-if="promotions.length > 0" id="promotionHelp" class="form-text text-muted">
          Select a campaign first. Which promotion will this be a part of?
        </small>
      </div>

      <div class="form-row">
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <label for="p-first">First Name <span class="text-danger">*</span></label>
            <input
              v-model="pledge.first"
              type="text"
              class="form-control"
              id="p-first"
              name="first"
              placeholder="John"
              required
            />
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <label for="p-last">Last Name <span class="text-danger">*</span></label>
            <input
              v-model="pledge.last"
              type="text"
              class="form-control"
              id="p-last"
              name="last"
              placeholder="Smith"
              required
            />
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="p-display_as">Display As</label>
            <input
              type="text"
              class="form-control"
              id="p-display_as"
              name="display_as"
              aria-describedby="displayAsHelp"
              placeholder="e.g. Johnny Smith"
              v-model="pledge.display_as"
            />
            <small id="displayAsHelp" class="form-text text-muted">
              Use for a custom display name in place of the pledger's first and last name.
            </small>
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <div class="checkbox mb-3">
              <label for="p-anon">Anonymous?</label>
              <div class="custom-control custom-switch">
                <input
                  v-model="pledge.anon"
                  type="checkbox"
                  id="p-anon"
                  name="anon"
                  class="custom-control-input"
                />
                <label class="custom-control-label" for="p-anon"></label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <div class="checkbox mb-3">
              <label for="p-active">Active?</label>
              <div class="custom-control custom-switch">
                <input
                  v-model="pledge.active"
                  type="checkbox"
                  id="p-active"
                  name="active"
                  class="custom-control-input"
                />
                <label class="custom-control-label" for="p-active"></label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <h4>Contact Info</h4>

      <div class="form-row">
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <label for="p-email">Email Address</label>
            <input
              v-model="pledge.email"
              type="text"
              class="form-control"
              id="p-email"
              name="email"
            />
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <label for="p-phone">Phone Number</label>
            <input
              v-model="pledge.phone"
              type="text"
              class="form-control"
              id="p-phone"
              name="phone"
            />
          </div>
        </div>
      </div>

      <div v-if="!showMailingAddress" class="form-group">
        <button type="button" class="btn btn-sm btn-dark" @click="showMailingAddress = true">
          <font-awesome-icon icon="map-marked-alt" />
          Add mailing address?
        </button>
      </div>
      <fieldset v-else>
        <div class="form-group">
          <label for="p-street">Street Address</label>
          <input
            v-model="pledge.street"
            type="text"
            class="form-control"
            id="p-street"
            name="street"
          />
        </div>

        <div class="form-row align-items-center">
          <div class="col-12 col-sm-6">
            <div class="form-group">
              <label for="p-city">City</label>
              <input
                v-model="pledge.city"
                type="text"
                class="form-control"
                id="p-city"
                name="city"
              />
            </div>
          </div>
          <div class="col-12 col-sm-3">
            <div class="form-group">
              <label for="p-state">State</label>
              <select v-model="pledge.state" class="form-control" id="p-state" name="state">
                <option value="" readonly>- Select -</option>
                <option
                  v-for="(state, index) in states"
                  :key="`state-${index}`"
                  :value="state.code"
                >
                  {{ state.code }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-12 col-sm-3">
            <div class="form-group">
              <label for="p-zip">Zip</label>
              <input v-model="pledge.zip" type="text" class="form-control" id="p-zip" name="zip" />
            </div>
          </div>
        </div>

        <div class="form-group">
          <label for="p-country">Country</label>
          <select v-model="pledge.country" class="form-control" id="p-country" name="country">
            <option value="" readonly>- Select -</option>
            <option
              v-for="(country, index) in countries"
              :key="`country-${index}`"
              :value="country.code"
            >
              {{ country.name }}
            </option>
          </select>
        </div>
      </fieldset>

      <h4>Pledge</h4>

      <fieldset>
        <div class="form-group">
          <label for="p-total">Total <span class="text-danger">*</span></label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">$</span>
            </div>
            <input
              v-model="pledge.total"
              type="number"
              step="0.01"
              min="0.01"
              class="form-control"
              id="p-total"
              name="total"
              required
            />
          </div>
        </div>
      </fieldset>

      <fieldset>
        <div class="form-group">
          <label>Promised At?</label>
          <div class="row">
            <div class="col-12 col-sm-6">
              <label class="text-sm">Choose a Date</label>
              <v-date-picker
                v-model="promisedAtDate"
                v-on:input="onDateChange('promised_at', $event)"
                @dayclick="
                  (_, event) => {
                    event.target.blur();
                  }
                "
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <input
                    id="p-promised-at-date"
                    class="form-control"
                    :value="inputValue"
                    v-on="inputEvents"
                    required
                  />
                </template>
              </v-date-picker>
            </div>
            <div class="col-12 col-sm-6">
              <label class="text-sm">Choose a Time</label>
              <vue-timepicker
                id="p-promised-at-time"
                name="promised_at_time"
                v-model="promisedAtTime"
                format="hh:mm A"
                :minute-interval="5"
                auto-scroll
                placeholder="HH:MM AM/PM"
                input-class="form-control bg-white"
                input-width="100%"
              />
            </div>
          </div>
          <small id="promotion-promised-dates-help" class="form-text text-muted">
            The <code>Promised At</code> date is when the pledge was made.
          </small>
        </div>
      </fieldset>

      <fieldset>
        <div class="form-group">
          <label>Includable At?</label>
          <div class="row">
            <div class="col-12 col-sm-6">
              <label class="text-sm">Choose a Date</label>
              <v-date-picker
                v-model="includableAtDate"
                v-on:input="onDateChange('includable_at', $event)"
                @dayclick="
                  (_, event) => {
                    event.target.blur();
                  }
                "
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <input
                    id="p-includable-at-date"
                    class="form-control"
                    :value="inputValue"
                    v-on="inputEvents"
                    required
                  />
                </template>
              </v-date-picker>
            </div>
            <div class="col-12 col-sm-6">
              <label class="text-sm">Choose a Time</label>
              <vue-timepicker
                id="p-includable-at-time"
                name="includable_at_time"
                v-model="includableAtTime"
                format="hh:mm A"
                :minute-interval="5"
                auto-scroll
                placeholder="HH:MM AM/PM"
                input-class="form-control bg-white"
                input-width="100%"
              />
            </div>
          </div>
          <small id="promotion-include-dates-help" class="form-text text-muted">
            The <code>Includable At</code> date is when the pledge can be counted in the promotion
            totals.
          </small>
        </div>
      </fieldset>
    </fieldset>

    <Error v-if="error" :error="error" />

    <div class="form-row justify-content-between">
      <button type="submit" class="btn btn-success" :disabled="isLocked">
        <font-awesome-icon icon="save" /> Save
      </button>
      <button type="button" class="btn btn-outline-danger" :disabled="isLocked" @click="modalClose">
        <font-awesome-icon icon="window-close" /> Cancel
      </button>
    </div>
  </form>
</template>

<script>
/*global $*/
import * as _ from 'lodash';
import dayjs from 'dayjs';
import Error from 'components/Error';
import formHelpers from 'mixins/formHelpers';
import FormError from './FormError';
import hasTimeDate from 'mixins/hasTimeDate';
import helpers from 'mixins/helpers';
import VueTimepicker from 'vue3-timepicker';

export default {
  name: 'PledgeForm',
  mixins: [hasTimeDate, helpers, formHelpers],
  components: { Error, FormError, VueTimepicker },
  props: {
    initial: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    modal: {
      type: String,
      required: false,
    },
  },
  data() {
    // Initialize the Promotion
    const initializedPledge = this.initializePledge();

    const pledgePromisedAtTime = initializedPledge.promised_at
      ? this.verifyDayjs(initializedPledge.promised_at)
      : dayjs().startOf('hour');

    const pledgeIncludableAtTime = initializedPledge.includable_at
      ? this.verifyDayjs(initializedPledge.includable_at)
      : dayjs().startOf('hour');

    return {
      promisedAtDate: pledgePromisedAtTime.toDate(),
      promisedAtTime: {
        hh: pledgePromisedAtTime.format('hh'),
        mm: pledgePromisedAtTime.format('mm'),
        A: pledgePromisedAtTime.format('A'),
      },
      includableAtDate: pledgeIncludableAtTime.toDate(),
      includableAtTime: {
        hh: pledgeIncludableAtTime.format('hh'),
        mm: pledgeIncludableAtTime.format('mm'),
        A: pledgeIncludableAtTime.format('A'),
      },
      allowedFields: [
        'pledge_id',
        'campaign_id',
        'promotion_id',
        'intention_id',
        'type',
        'first',
        'last',
        'display_as',
        'anon',
        'email',
        'phone',
        'street',
        'city',
        'state',
        'zip',
        'country',
        'latitude',
        'longitude',
        'occurrence',
        'interval',
        'channel',
        'amount',
        'total',
        'promised_at',
        'includable_at',
      ],
      pledge: this.initializePledge(),
      error: null,
      errorCampaigns: null,
      errorPromotion: null,
      errorIntentions: null,
      errorLatLng: null,
      isLocked: false,
      isLoadingCampaigns: false,
      isLoadingPromotion: false,
      isLoadingIntentions: false,
      channels: [
        {
          value: 'website',
          name: 'Website',
        },
        {
          value: 'phone',
          name: 'Phone',
        },
        {
          value: 'email',
          name: 'Email',
        },
        {
          value: 'sms',
          name: 'SMS',
        },
        {
          value: 'mms',
          name: 'MMS',
        },
        {
          value: 'social',
          name: 'Social',
        },
        {
          value: 'person',
          name: 'Person',
        },
      ],
      intervals: [
        {
          value: 'single',
          name: 'Single',
        },
        {
          value: 'daily',
          name: 'Daily',
        },
        {
          value: 'weekly',
          name: 'Weekly',
        },
        {
          value: 'monthly',
          name: 'Monthly',
        },
        {
          value: 'quarterly',
          name: 'Quarterly',
        },
        {
          value: 'biannual',
          name: 'Biannual',
        },
        {
          value: 'annual',
          name: 'Annual',
        },
      ],
      campaign: {},
      promotions: [],
      showMailingAddress: false,
    };
  },
  created() {
    // console.log('PledgeForm created()');
    // fetch the data when the view is created and the data is
    // already being observed
    this.fetchData();
  },
  computed: {
    campaigns() {
      return this.$store.getters['campaigns/getAll'];
    },
    promotion() {
      return this.$store.getters['promotions/getCurrentPromotion'];
    },
    intention() {
      const intentions = this.$store.getters['intentions/getAll'];
      return intentions.shift();
    },
    campaignOptions() {
      const campaignOptions = [];

      this.campaigns.forEach((campaign) => {
        let campaignTitle = `${campaign.title} (${dayjs(campaign.starts_at).format('M/D')}-${dayjs(
          campaign.ends_at
        ).format('M/D')})`;

        if (this.isCurrent(campaign.starts_at, campaign.ends_at)) {
          campaignTitle = `${campaignTitle} (Current)`;
        }

        campaignOptions.push({
          id: campaign.campaign_id,
          text: campaignTitle,
        });
      });

      return campaignOptions;
    },
    promotionOptions() {
      const promotionOptions = [];

      this.promotions.forEach((promotion) => {
        let promotionTitle = `${promotion.title} (${dayjs(promotion.starts_at).format(
          'M/D h:mm A'
        )})`;

        if (this.isCurrent(promotion.starts_at, promotion.ends_at)) {
          promotionTitle = `${promotionTitle} (Current - ${dayjs(promotion.starts_at).format(
            'M/D h:mm A'
          )})`;
        }

        promotionOptions.push({
          id: promotion.promotion_id,
          text: promotionTitle,
        });
      });

      return promotionOptions;
    },
  },
  methods: {
    onDateChange(field, date) {
      // console.log('PledgeForm methods onDateChange() :field, :date', { field, date });
      this.pledge[field] = dayjs(date).toISOString();
    },
    fetchData() {
      this.errorCampaigns = null;
      this.isLoadingCampaigns = true;

      // Get the Campaigns for the dropdown. We get "all" because there shouldn't be that many.
      // #TODO maybe filter this by current and upcoming?
      this.$store
        .dispatch('campaigns/getAll', {
          params: {
            order_by: 'starts_at',
            has: 'promotions',
            with: 'promotions',
          },
        })
        .then(() => {
          // console.log('PledgeForm methods fetchData() then');
          if (this.initial.campaign_id) {
            // console.log('PledgeForm methods fetchData() :campaign_id', this.initial.campaign_id);
            this.setCampaign({ target: { value: this.initial.campaign_id } });
          }

          if (this.initial.promotion_id) {
            // console.log('PledgeForm methods fetchData() :promotion_id', this.initial.promotion_id);
            this.getIntentions({ target: { value: this.initial.promotion_id } });
          }

          this.isLoadingCampaigns = false;
        })
        .catch((error) => {
          this.errorCampaigns = this.$errorProcessor(error);
        });
    },
    formatAsNumber(property, event) {
      // console.log('PledgeForm methods formatAsNumber()', property, event.target.value);
      this.pledge[property] = Number(event.target.value);
    },
    handleSubmit() {
      console.log('PledgeForm methods handleSubmit()', this.pledge);
      // Lock the buttons
      this.isLocked = true;

      // Promise.all([this.getLatLng()]).then(() => {
      // });

      const payload = {
        formData: this.toFormData(this.prepareData()),
        options: {
          // We are setting 'PUT' here because we are
          // replacing the entire object. Future iterations
          // may only adjust some parts of the model.
          method: 'PUT',
        },
      };
      // Set the URL to either create or update
      let url;
      if (this.isNew()) {
        url = 'pledges/create';
      } else {
        url = 'pledges/update';
        payload.options.id = this.pledge.pledge_id;
      }

      // console.log('PledgeForm methods handlesubmit() :payload', payload);

      // Send to action
      this.$store
        .dispatch(url, payload)
        .then((response) => {
          // on success, close modal, unlock buttons
          console.log('PledgeForm methods handleSubmit() then', response);
          this.$emit('complete');
          this.modalClose();
        })
        .catch((error) => {
          // on error, highlight errors
          console.error('PledgeForm methods handleSubmit() catch', error);
          this.error = this.$errorProcessor(error);
        })
        .finally(() => {
          this.isLocked = false;
        });
    },
    hasError(field) {
      if (this.error && this.error.errorBag) {
        // Check if the error bag has an entry for this
        return _.has(this.error.errorBag, field);
      }

      return false;
    },
    initializePledge() {
      return Object.assign(
        {
          campaign_id: '',
          promotion_id: '',
          // intention_id: '',
          type: 'pledge',
          first: '',
          last: '',
          display_as: '',
          anon: false,
          email: '',
          phone: '',
          street: '',
          city: '',
          state: '',
          zip: '',
          country: '',
          latitude: '',
          longitude: '',
          occurrence: 1,
          interval: 'single',
          channel: 'website',
          amount: '',
          total: '',
          promised_at: this.getCurrentISODate(),
          includable_at: this.getCurrentISODate(),
        },
        this.initial
      );
    },
    isCurrent(start, end) {
      // Is this Campaign currently running?
      return this.isBetween(start, end);
    },
    getPromotion(event) {
      this.errorPromotion = null;
      this.isLoadingPromotion = true;

      console.error('PledgeForm methods getPromotion() before :id', event.target.value);

      this.$store
        .dispatch('promotions/get', { id: event.target.value })
        .then((response) => {
          console.error('PledgeForm methods getPromotion() then', response);
          this.isLoadingPromotion = false;
        })
        .catch((error) => {
          // on error, highlight errors
          console.error('PledgeForm methods getPromotion() catch', error);
          this.errorPromotion = this.$errorProcessor(error);
        });
    },
    getIntentions(event) {
      this.errorIntentions = null;
      this.isLoadingIntentions = true;

      console.log('PledgeForm methods getIntentions() before :id', event.id);

      this.$store
        .dispatch('promotions/getIntentions', { id: event.id })
        .then((response) => {
          console.log('PledgeForm methods getIntentions() then', response);
          this.isLoadingIntentions = false;
        })
        .catch((error) => {
          // on error, highlight errors
          console.error('PledgeForm methods getIntentions() catch', error);
          this.errorIntentions = this.$errorProcessor(error);
        });
    },
    modalClose() {
      // reset the Pledge
      this.pledge = this.initializePledge();
      this.campaign = {};
      this.promotions = [];
      this.error = null;
      this.errorCampaigns = null;
      this.errorPromotion = null;
      this.errorIntentions = null;
      this.errorLatLng = null;
      this.isLocked = false;
      this.isLoadingCampaigns = false;
      this.isLoadingPromotion = false;
      this.isLoadingIntentions = false;

      // if we have a modal parent, close it
      if (this.modal) {
        $(this.modal).modal('hide');
      }
    },
    prepareData() {
      // format as Number
      this.pledge.anon = Number(this.pledge.anon);

      if (this.intention) {
        this.pledge.intention_id = this.intention.intention_id;
      }

      const promisedAtTime = this.convert12To24(this.promisedAtTime);

      this.pledge.promised_at = dayjs(this.promisedAtDate)
        .set('hour', promisedAtTime.hh)
        .set('minute', promisedAtTime.mm)
        .toISOString();

      const includableAtTime = this.convert12To24(this.includableAtTime);

      this.pledge.includable_at = dayjs(this.includableAtDate)
        .set('hour', includableAtTime.hh)
        .set('minute', includableAtTime.mm)
        .toISOString();

      // set pledge amount to total
      this.pledge.amount = this.pledge.total;

      return this.pledge;
    },
    getLatLng() {
      const { street, city, state, zip, country } = this.pledge;
      const address = [street, city, state, zip, country].join(' ');

      const latlng = this.$store
        .dispatch('pledges/getCoordinates', address)
        .then((response) => {
          // console.log('PledgeForm methods getLatLng() then', response);
          this.pledge.latitude = response.geometry.location.lat;
          this.pledge.longitude = response.geometry.location.lng;
        })
        .catch((error) => {
          // on error, highlight errors
          console.error('PledgeForm methods getLatLng() catch', error);
          this.errorLatLng = this.$errorProcessor(error);
        });

      // console.log(
      //   'PledgeForm methods getLatLng() after :street :city :state :zip :country :address',
      //   { street, city, state, zip, country, address }
      // );

      return latlng;
    },
    setCampaign(event) {
      // console.log('PledgeForm methods setCampaign() :event', event.id);
      const campaign = this.campaigns
        .filter((campaign) => {
          // return campaign.campaign_id === Number(event.target.value);
          return campaign.campaign_id === Number(event.id);
        })
        .shift();
      // console.log('PledgeForm methods setCampaign() :campaign', campaign);

      if (campaign) {
        this.campaign = campaign;

        const { promotions } = campaign;

        if (promotions) {
          promotions.sort((a, b) => (dayjs(a.starts_at).isAfter(dayjs(b.starts_at)) ? -1 : 1));
        }

        this.promotions = promotions ? promotions : [];
      }
    },
  },
};
</script>

<style lang="scss">
@import '~vue3-timepicker/dist/VueTimepicker.css';

.vue__time-picker {
  input {
    &.vue__time-picker-input {
      border: 1px solid #ced4da;
      height: calc(1.5em + 0.75rem + 2px);
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
    }
  }

  .dropdown {
    ul {
      li:not([disabled]) {
        &.active {
          &,
          &:focus,
          &:hover {
            background: var(--primary);
          }
        }
      }
    }
  }
}
</style>
