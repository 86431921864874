<template>
  <div class="container app-content pt-4">
    <!-- Page Header -->
    <PageHeader :title="campaign.title" />

    <Error v-if="errorPromotions" :error="errorPromotions" />
    <Loading v-else-if="isLoadingPromotions" class="mt-4" />
    <template v-else>
      <PromotionsNavigation
        @searchQuery="searchQuery"
        @showPromotionCreateForm="showPromotionCreateForm = true"
      />
      <div class="row">
        <div class="col-3 m-auto" v-if="!hasPromotions">
          <EmptyCard title="There are no promotions" />
        </div>
        <template v-else>
          <div class="col-12">
            <PaginationResults
              :total="meta.pagination.total"
              :current-page="pageNum"
              :total-pages="meta.pagination.total_pages"
              :per-page="perPage"
              @searchQuery="searchQuery"
            />
          </div>
          <div
            class="col-sm-6 col-md-4 d-flex"
            v-for="promotion in promotions"
            :key="`promotion-card-${promotion.promotion_id}`"
          >
            <PromotionCard
              :promotion="promotion"
              :campaign="campaign"
              :show-campaign="false"
              @setPromotion="setPromotion"
            />
          </div>
        </template>
      </div>
      <Pagination
        :total="meta.pagination.total"
        :current-page="pageNum"
        :total-pages="meta.pagination.total_pages"
        :per-page="perPage"
        @pageFirst="pageFirst"
        @pageLast="pageLast"
        @pageNext="pageNext"
        @pagePrevious="pagePrevious"
        @toPage="toPage"
        @setPerPage="setPerPage"
      />
    </template>

    <Modal id="modal-promotion-create">
      <template v-slot:title>Create Promotion</template>

      <template v-slot:body>
        <PromotionForm
          v-if="showPromotionCreateForm"
          :campaign="campaign"
          modal="#modal-promotion-create"
          @complete="fetchData()"
          @close="showPromotionCreateForm = false"
        />
      </template>
    </Modal>

    <Modal id="modal-promotion-edit">
      <template v-slot:title>Edit Promotion</template>

      <template v-slot:body>
        <PromotionForm
          v-if="showPromotionEditForm"
          :initial="editPromotion"
          modal="#modal-promotion-edit"
          @complete="fetchData()"
          @close="showPromotionEditForm = false"
        />
      </template>
    </Modal>
  </div>
</template>

<script>
import paginationFunctions from 'mixins/paginationFunctions';
import EmptyCard from 'components/status-cards/EmptyCard';
import Error from 'components/Error';
import Loading from 'components/Loading';
import Modal from 'components/Modal';
import PageHeader from 'components/PageHeader';
import Pagination from 'components/Pagination';
import PaginationResults from 'components/PaginationResults';
import PromotionCard from 'components/promotions/PromotionCard';
import PromotionForm from 'components/forms/PromotionForm';
import PromotionsNavigation from 'components/promotions/PromotionsNavigation';

export default {
  name: 'CampaignPromotionsIndex',
  mixins: [paginationFunctions],
  components: {
    EmptyCard,
    Error,
    Loading,
    Modal,
    PageHeader,
    Pagination,
    PaginationResults,
    PromotionCard,
    PromotionForm,
    PromotionsNavigation,
  },
  props: {
    id: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      editPromotion: {},
      error: null,
      errorPromotions: null,
      isLoading: false,
      isLoadingPromotions: false,
      pageNum: Number(this.$route.query.page) || 1,
      perPage: Number(this.$route.query.per_page) || 12,
      showPromotionCreateForm: false,
      showPromotionEditForm: false,
    };
  },
  computed: {
    campaign() {
      return this.$store.getters['campaigns/getCurrentCampaign'];
    },
    promotions() {
      return this.$store.getters['promotions/getAll'] ?? [];
    },
    hasPromotions() {
      return this.promotions.length > 0;
    },
    meta() {
      return this.$store.getters['promotions/getAllMeta'] ?? {};
    },
  },
  created() {
    // fetch the data when the view is created and the data is
    // already being observed
    this.fetchData();
  },
  methods: {
    fetchData() {
      // console.log('CampaignPromotionsIndex methods fetchData() started');
      this.error = null;
      this.errorPromotions = null;
      this.isLoading = true;
      this.isLoadingPromotions = true;
      // hide the Promotion Edit Form if visible
      this.showPromotionCreateForm = false;
      this.showPromotionEditForm = false;

      const promotionsParams = {
        id: this.id,
        params: {
          order_by: 'starts_at',
          order_dir: 'DESC',
          page: this.pageNum,
          per_page: this.perPage,
          with: 'statistics;intentions',
        },
        saveToStore: true,
      };

      this.$store
        .dispatch('campaigns/getPromotions', promotionsParams)
        .then(() => {
          this.isLoadingPromotions = false;
        })
        .catch((error) => {
          this.errorPromotions = this.$errorProcessor(error);
        });

      const campaignParams = {
        id: this.id,
        params: {},
      };
      this.$store
        .dispatch('campaigns/get', campaignParams)
        .then(() => {
          this.isLoading = false;
        })
        .catch((error) => {
          this.error = this.$errorProcessor(error);
        });
    },
    compare(value1, value2, key) {
      return key === 'creation' ? true : undefined;
    },
    searchQuery() {
      // console.log('CampaignsIndex methods searchQuery()');
      this.pageNum = 1;

      this.fetchData();
    },
    setPromotion(promotion) {
      this.editPromotion = promotion;
      this.showPromotionEditForm = true;
    },
  },
};
</script>

<style scoped lang="scss">
/*.card-group {
  .card {
    flex-basis: 25%;
    flex-grow: 0;
  }
}*/
</style>
